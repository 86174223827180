import React, {useEffect, useState} from 'react'
import TransitionLink, {TransitionState} from 'gatsby-plugin-transition-link'

import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import SEO from '../components/shared/seo'
import SplitText from 'react-pose-text'
import posed from 'react-pose'
import styled from 'styled-components'
import {useLayoutValue} from '../components/ui/layoutProvider'
import {useMediaQuery} from 'react-responsive'


const SlideDiv = posed.div({
  enter: {
    x: 0,
    opacity: 0,
    width: '0%',
    background: 'rgba(211, 211, 211, 1)',
    transition: {
      background: {ease: 'linear', damping: 20, delay: 100},
      width: {ease: 'linear', damping: 20, delay: 100, duration: 1000},
    },
  },
  exit: {
    x: 0,
    width: '100%',
    opacity: 100,
    background: 'rgba(255, 255, 255, 1)',
    transition: {
      background: {ease: 'linear', damping: 20, delay: 100, duration: 1000},
      width: {ease: 'linear', damping: 500, delay: 100, duration: 1000},
    },
  },
})

const LinkUnderline = styled(SlideDiv)`
  position: relative;
  width: 100%;
  height: 2px;
`

const IndexPage = ({data, className}) => {
  const {state, dispatch} = useLayoutValue()
  const isMobile = useMediaQuery({maxWidth: 1224})
  // only force open menu on bigger res than mobile
  // useEffect(() => {
  //   if (!isMobile) {
  //     dispatch({
  //       type: 'SET_MENU',
  //       value: false,
  //     })
  //   }
  // }, [])
  return (
    <>
      <SEO
        title="John Dore | Computer Art"
        keywords={[`Computational Art`, `T1`, `John Dore`, `Plotter Drawings`]}
      />
      <main className="w-full flex flex-col justify-start items-start md:mx-10 px-6 pl-4 md:p-0 mt-64">
        <BackgroundImage className={className} fluid={data.allImageSharp.edges[0].node.fluid}>
        </BackgroundImage>
      </main>
    </>
  )
}

const StyledIndex = styled(IndexPage)`
  width: 100%;
  height: 420px;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-start;
  background-position: left top;
  z-index: 0;
`

export const query = graphql`
  query {
    allImageSharp(filter: {fluid: {originalName: {eq: "indexlogo.png"}}}) {
      edges {
        node {
          id
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            originalName
          }
          original {
            src
          }
        }
      }
    }
  }
`

export default StyledIndex
